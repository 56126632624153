const authentication = (ui) => {
  return {
    basic ({ username, password }) {
      return ui.http.post('/login', { username, password })
        .then(body => {
          if (!body) {
            return
          }

          return body.data
        })
    }
  }
}

export { authentication }
