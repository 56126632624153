import Vue from 'vue'
import App from './App.vue'
import routes from './routes'
import store from './store'

import { UI } from '@cyradar/ui'
import { authentication } from './api'
import localization from './localization'
import { normalizeResult } from '@/utils'

Vue.use(UI)

const ui = new UI({
  routes,
  store,
  auth: true,
  authAPI: authentication,
  localization,
  instance: {
    show: process.env.VUE_APP_INSTANCE || process.env.VUE_APP_INSTANCE_LOGO,
    name: process.env.VUE_APP_INSTANCE,
    logo: process.env.VUE_APP_INSTANCE_LOGO,
    link: process.env.VUE_APP_INSTANCE_LINK
  },
  logo: {
    light: '/logo.png',
    dark: '/logo-white.png'
  },
  mode: 'dark'
})
Vue.config.productionTip = false

Vue.mixin({
  data () {
    return {
      $_oldState: null
    }
  },
  methods: {
    $_search ({ query, start, end, history, from, pure }) {
      return this.$http
        .post('/api/v1/search', {
          query_string: query,
          start_time: start instanceof Date ? start.getTime() : start,
          end_time: end instanceof Date ? end.getTime() : end,
          history: !!history,
          from: from || 0
        })
        .then(body => {
          if (!body || !body.data) {
            return
          }

          if (pure) {
            return body.data.data
          }

          return normalizeResult(body.data.data)
        })
    },
    $_stateClone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    $_statePersist (state) {
      this.$_oldState = this.$_stateClone(state)
    },
    $_stateRestore (keys) {
      if (!this.$_oldState) {
        return []
      }

      return keys.map(key => {
        return this.$_oldState[key]
      })
    }
  }
})

new Vue({
  ui,
  render: h => h(App)
}).$mount('#app')
