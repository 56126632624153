export * from './chartutils.js'
export const isJSON = str => {
  if (typeof str !== 'string') {
    return false
  }

  try {
    return (JSON.parse(str) && !!str)
  } catch (e) {
    return false
  }
}

export const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this; var args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const chunk = (arr, size) => {
  const result = []
  const len = arr.length
  for (let i = 0; i < len; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}

export { dotGet, dotSet } from 'teible'
