import { dashboard, search, alert, note, archive, plug, repoPull, radioTower, organization, circuitBoard } from 'octicons-vue'

export default [
  {
    path: '/',
    name: 'dashboards',
    component: () => import(/* webpackChunkName: "dashboards" */ './views/Home.vue'),
    meta: {
      title: 'Home',
      icon: dashboard
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
    meta: {
      title: 'Search',
      icon: search
    }
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ './views/Alerts.vue'),
    meta: {
      title: 'Alerts',
      icon: alert
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ './views/Reports.vue'),
    meta: {
      title: 'Reports',
      icon: note
    },
    children: [
      {
        path: ':id',
        name: 'reports-details',
        component: () => import(/* webpackChunkName: "reports" */ './views/ReportsDetails.vue'),
        meta: {
          title: 'Reports Details',
          show: false
        }
      }
    ]
  },
  // {
  //   path: '/incidents',
  //   name: 'incidents',
  //   component: () => import(/* webpackChunkName: "indexes" */ './views/Incidents.vue'),
  //   meta: { title: 'Incidents', group: 'Incident Response', icon: issueClosed }
  // },
  // {
  //   path: '/tickets',
  //   name: 'tickets',
  //   component: () => import(/* webpackChunkName: "indexes" */ './views/Tickets.vue'),
  //   meta: { title: 'Tickets', group: 'Incident Response', icon: tag }
  // },
  // {
  //   path: '/actions',
  //   name: 'actions',
  //   component: () => import(/* webpackChunkName: "indexes" */ './views/Actions.vue'),
  //   meta: { title: 'Actions', group: 'Incident Response', icon: play }
  // },
  // {
  //   path: '/playbooks',
  //   name: 'playbooks',
  //   component: () => import(/* webpackChunkName: "indexes" */ './views/Playbooks.vue'),
  //   meta: { title: 'Playbooks', group: 'Incident Response', icon: repoClone }
  // },
  {
    path: '/indexes',
    name: 'indexes',
    component: () => import(/* webpackChunkName: "indexes" */ './views/SettingsIndexes.vue'),
    meta: { title: 'Indexes', group: 'Settings', icon: archive }
  },
  {
    path: '/source-types',
    name: 'sourceTypes',
    component: () => import(/* webpackChunkName: "source-types" */ './views/SettingsSourceTypes.vue'),
    meta: { title: 'Sources Types', group: 'Settings', icon: plug }
  },
  {
    path: '/data-inputs',
    name: 'dataInputs',
    component: () => import(/* webpackChunkName: "data-inputs" */ './views/SettingsDataInputs.vue'),
    meta: { title: 'Data Inputs', group: 'Settings', icon: repoPull }
  },
  {
    path: '/forwarders',
    name: 'forwarders',
    component: () => import(/* webpackChunkName: "forwarders" */ './views/Forwarders.vue'),
    meta: { title: 'Forwarders', group: 'Settings', icon: radioTower }
  },
  {
    path: '/api-channels',
    name: 'api-channels',
    component: () => import(/* webpackChunkName: "api-channels" */ './views/APIChannels.vue'),
    meta: { title: 'API Channels', group: 'Settings', icon: circuitBoard }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
    meta: { title: 'Users', group: 'Settings', icon: organization }
  }
  // {
  //   path: '/parser',
  //   name: 'parser',
  //   component: () => import(/* webpackChunkName: "users" */ './views/LogParser.vue'),
  //   meta: { title: 'parser', group: 'Settings', icon: plus }
  // }
]
