import { dotSet } from '@/utils'

export const charts = () => [
  {
    name: 'Line Chart',
    value: 'line'
  },
  {
    name: 'Pie Chart',
    value: 'pie'
  },
  {
    name: 'Bar Chart',
    value: 'bar'
  },
  {
    name: 'Column Chart',
    value: 'column'
  },
  {
    name: 'Table',
    value: 'table'
  },
  {
    name: 'Sum',
    value: 'sum'
  },
  {
    name: 'Map',
    value: 'map'
  },
  {
    name: 'BPMap',
    value: 'bpmap'
  }
]

export const colors = [
  {
    name: 'Default',
    value: ''
  },
  {
    name: 'Blue',
    value: 'blue'
  },
  {
    name: 'Teal',
    value: 'teal'
  },
  {
    name: 'Green',
    value: 'green'
  },
  {
    name: 'Yellow',
    value: 'yellow'
  },
  {
    name: 'Red',
    value: 'red'
  }
]

export function makeDuration (interval) {
  if (!interval || !interval.value || !interval.unit) {
    return 0
  }

  if (interval.unit === 'm') {
    return interval.value * 60 * 1000
  }

  if (interval.unit === 'h') {
    return interval.value * 60 * 1000 * 60
  }

  if (interval.unit === 'd') {
    return interval.value * 60 * 1000 * 60 * 24
  }

  return 0
}

export function makePieData (arr) {
  return {
    columns: arr.map((x) => [x.key, x.value])
  }
}

export function makeBarData (arr) {
  return {
    x: 'x',
    columns: [
      ['x', ...arr.map((x) => x.key)],
      ['data', ...arr.map((x) => x.value)]
    ]
  }
}

export function makeLineData (arr, interval, startTime, endTime) {
  const x = 'dates'
  const columns = [[x]]
  const output = {
    columns,
    x,
    duration: 0
  }

  if (!startTime || !endTime) {
    return output
  }

  if (!arr || !arr.length) {
    return output
  }

  output.duration = makeDuration(interval)
  if (!arr[0] || !arr[0].data || !arr[0].data[0] || !arr[0].data[0][0]) {
    return output
  }

  const offset = arr[0].data[0][0] - startTime
  if (offset < 0) {
    startTime = arr[0].data[0][0]
  } else {
    const startTimeNew =
      arr[0].data[0][0] - Math.ceil(offset / output.duration) * output.duration
    endTime = endTime + Math.abs(startTime - startTimeNew)
    startTime = startTimeNew
  }

  for (let moment = startTime; moment <= endTime; moment += output.duration) {
    columns[0].push(new Date(moment))
    arr.forEach((line, i) => {
      if (!columns[i + 1]) {
        columns[i + 1] = [line.name]
      }

      const item = line.data.find((e) => e[0] === moment)
      if (!item) {
        columns[i + 1].push(0)
        return
      }

      columns[i + 1].push(item[1])
    })
  }

  return output
}

export function normalizeResult (result) {
  if (!result) {
    return
  }

  const base = {
    types: null,
    events: [],
    start_time: result.start_time,
    end_time: result.end_time
  }

  // const motchiecmapthatxinh = {
  //   '10.3.145.46': 'bactanuyen'
  // }

  // base.data = result.data.reduce(function (map, obj) {
  //   map[motchiecmapthatxinh[obj.key]] = obj
  //   return map
  // }, {})

  if (
    result.chart_type === 'count_statistic' &&
    result.key === 'agentInfo.groupKey'
  ) {
    const map = {
      binhphuoc_budop: ['BDO@YUPLKQWECDSHG'],
      binhphuoc_bugiamap: ['BGM@MIYDKDUDYALOF'],
      binhphuoc_chonthanh: ['CT@MIYMEDERUDYALOF'],
      binhphuoc_dongphu: ['DP@TYQKLGNMBCY'],
      binhphuoc_honquan: ['HQ@EHGKLDTUIEF'],
      binhphuoc_locninh: ['LN@EDRFTYUIWMBQW'],
      binhphuoc_phurieng: ['Pr@rtyuioplkjhgf'],
      binhphuoc_budang: ['2WXWSQ', '3BDTYE'],
      binhphuoc_binhlong: ['BL@ZUYTMWADDMP'],
      binhphuoc_phuoclong: ['PL@YTUIOPOILREQER']
    }

    // i would be damned :(
    const events =
      result.data instanceof Array
        ? result.data.map((item) => {
          let district = 'binhphuoc_dongxoai'
          for (var d in map) {
            const keys = map[d]
            for (const key of keys) {
              if (item.key === key) {
                district = d
                return {
                  key: item.key,
                  district,
                  count: item.doc_count
                }
              }
            }
          }

          return {
            key: item.key,
            district,
            count: item.doc_count
          }
        })
        : []

    return {
      ...base,
      types: ['bpmap', 'pie'],
      key: result.key,
      events:
        result.data instanceof Array
          ? result.data.map((item) => {
            item.value = item.doc_count
            return item
          })
          : [],
      fields: ['key', 'value'].concat((result.fields || []).slice(2)),
      data: events.reduce((acc, val) => {
        if (!acc[val.district]) {
          acc[val.district] = {
            keys: [],
            count: 0
          }
        }

        acc[val.district].count += val.count
        acc[val.district].keys.push(val.key)
        return acc
      }, {})
    }
  }

  if (result.chart_type === 'events') {
    return {
      ...base,
      events: result.data instanceof Array ? result.data : []
    }
  }

  if (result.chart_type === 'delete') {
    return result
  }

  if (result.chart_type === 'count_statistic') {
    return {
      ...base,
      chartType: 'count_statistic',
      types: ['pie', 'bar', 'column', 'table'],
      key: result.key,
      interval: result.interval,
      events:
        result.data instanceof Array
          ? result.data.map((item) => {
            item.value = item.doc_count
            item.count = item.doc_count
            dotSet(item, result.key, item.key)
            return item
          })
          : [],
      fields: ['key', 'value'].concat((result.fields || []).slice(2)),
      fieldsName: [result.key, 'count']
    }
  }

  if (result.chart_type === 'time_statistic') {
    return {
      ...base,
      types: ['line'],
      key: result.key,
      interval: result.interval,
      events:
        result.data instanceof Array
          ? result.data.map((item) => {
            return {
              key: item.key,
              value: item.doc_count,
              data: item.data.buckets
            }
          })
          : [],
      fields: ['key', 'value', 'data']
    }
  }

  if (result.chart_type === 'table') {
    return {
      ...base,
      types: ['table'],
      fields: result.fields,
      events: result.data instanceof Array ? result.data : []
    }
  }

  if (result.chart_type === 'count') {
    return {
      ...base,
      types: ['sum'],
      total: result.total
    }
  }

  if (result.chart_type === 'map') {
    return {
      ...base,
      types: ['map'],
      currentLocation: result.here,
      data: result.data instanceof Array ? result.data : [],
      geo: result.geo instanceof Array ? result.geo : [],
      geoField: result.geo_field,
      geoRoot: result.geo_root
    }
  }
}
