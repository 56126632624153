export default {
  dateTimeFormats: {
    en: {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
      }
    },
    vi: {
      short: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
      }
    }
  },
  messages: {
    en: {
      markedIncidents: 'Marked as Incident',
      dontMarkedIncidents: 'not marked as Incident',
      actionsList: 'Actions List',
      hide: 'Hide',
      assign_placeholder: 'Choose user to assign note',
      forward_placeholder: 'Choose user to forward note',
      closed_message: 'closed the ticket at',
      pending_message: 'is pending',
      replyTo: 'Reply to',
      replied: 'replied',
      at: 'at',
      add_note_massage: 'Add A Note',
      created_ticket_massage: 'created the ticket for maintainer',
      assigned_to: 'assigned to',
      download_message: 'Click to download the attachment',
      reopen: 'Reopen',
      Unknow: 'Unknow',
      you: 'You',
      forward: 'Forward',
      addNote: 'Add Note',
      reply: 'Reply',
      infomation: 'Infomation',
      assignedTo: 'Assigned To',
      previous: 'Previous',
      next: 'Next',
      incidentsDetail: 'Incident Response Details',
      total: 'Total',
      changeStatus: 'Change Status',
      critical: 'Critical',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      maintainer: 'Maintainer',
      toDo: 'To Do',
      eventsViewer: 'Events Viewer',
      close: 'Close',
      createdAt: 'Created At',
      created: 'Created',
      alertToMaintainer: 'Alert After Creation',
      dontAlertToMaintainer: 'Don\'t Alert After Creation',
      remind: 'Set Deadline',
      dontRemind: 'Dont Set Deadline',
      deadline: 'Deadline',
      remindBeforeDeadline: 'Remind Before',
      events: 'Events',
      incidentsHistogram: 'Incident Response Histogram',
      unassigned: 'Unassigned',
      overdue: 'Overdue',
      pending: 'Pending',
      priority: 'Priority',
      closed: 'Closed',
      falsePositive: 'False Positive',
      inProcess: 'In Process',
      solved: 'Solved',
      add: 'Add',
      save: 'Save',
      customize: 'Customize',
      cancel: 'Cancel',
      edit: 'Edit',
      remove: 'Remove',
      delete: 'Delete',
      random: 'Random',
      copy: 'Copy',
      query: 'Query',
      schedule: 'Schedule',
      refresh: 'Refresh',
      derived: 'Derived',

      name: 'Name',
      title: 'Title',
      description: 'Description',
      details: 'Details',
      enabled: 'Enabled',
      disabled: 'Disabled',
      status: 'Status',
      creator: 'Creator',
      operator: 'Operator',
      value: 'Value',
      key: 'Key',
      lastAlert: 'Last Alert',
      settings: 'Settings',
      actions: 'Actions',
      loading: 'Loading...',
      ticketUpdateNotiSuccess: 'The ticket has been refreshed',
      ticketUpdateNoti: 'The ticket has been updated. Click sync to refresh',
      editWarning: 'you are editing another item. Are you sure you want to skip this item?',
      rus: 'Are you sure?',
      email: 'Email',
      protocol: 'Protocol',
      port: 'Port',
      accessIPs: 'Access IPs',
      types: 'Types',
      type: 'Type',
      index: 'Index',
      sourceType: 'Source Type',
      token: 'Token',
      delimiter: 'Event Delimiter',
      field_delimiter: 'Field Delimiter',
      comment: 'Comment',
      time: 'Time',
      fields: 'Fields',
      field: 'Field',
      format: 'Format',
      regexp: 'RegExp',
      autoDetect: 'Auto-Detection',
      autoFill: 'Auto-Filling',
      maxSize: 'Max Size',
      unit: 'Unit',
      modules: 'Modules',
      activeModules: 'Active Modules',
      lastEvent: 'Last Event',
      streams: 'Streams',
      paths: 'Paths',
      excludes: 'Excludes',
      intervals: 'Intervals',
      scopes: 'Scopes',
      password: 'Password',
      oldPassword: 'Old Password',
      confirmPassword: 'Confirm Password',
      lastReport: 'Last Report',
      repeat: 'Repeat',
      recipients: 'Recipients',
      dayOfTheWeek: 'Days Of The Week',
      dayOfTheMonth: 'Day Of The Month',
      selectDefault: 'Select one below',
      headersAndPayload: 'Headers & Payload',
      method: 'Method',
      url: 'URL',
      apiChannels: 'API Channels',
      tickets: 'Tickets',
      playbook: 'Playbook',
      slaTitle: 'Resolution within SLA',
      resTime: 'Average Response Time',
      param: 'Param',
      default: 'Default',
      logType: 'Log Type',
      editLog: 'Edit Input Log',
      log: 'log',

      newPlaybook: 'New Playbook',
      newDashboard: 'New Dashboard',
      newWidget: 'New Widget',
      newIncident: 'New Incident',
      newTicket: 'New Ticket',
      newAlert: 'New Alert',
      newAction: 'New Action',
      newSourceType: 'New Source Type',
      newDataInput: 'New Data Input',
      newIndex: 'New Index',
      newForwarder: 'New Forwarder',
      newReport: 'New Report',
      newUser: 'New User',
      changePassword: 'Change Password',
      newAPIChannel: 'New API Channel',

      errorNoWidgets: 'The page is empty',
      errorWidgetsCustomizing: 'Please save or cancel the customizing first',

      pageOf: 'Page {0}/{1}',

      route: {
        dashboards: 'Dashboards',
        search: 'Search',
        alerts: 'Alerts',
        reports: 'Reports',
        incidents: 'Incidents',
        tickets: 'Tickets',
        actions: 'Actions',
        playBooks: 'Playbooks',
        indexes: 'Indexes',
        sourceTypes: 'Source Types',
        dataInputs: 'Data Inputs',
        users: 'Users',
        Settings: 'Settings',
        forwarders: 'Forwarders',
        apiChannels: 'API Channels',
        parser: 'Log Parser'
      },
      subtitle: {
        search: 'Events querying and Visualization',
        alerts: 'Monitoring based on metric values',
        incidents: 'Incident response management',
        tickets: 'Tickets management',
        actions: 'Actions management',
        playBooks: 'Playbooks management',
        indexes: 'Search engine index management',
        reports: 'Summary reports on system security',
        sourceTypes: 'Type definitions for sources',
        dataInputs: 'Source Management',
        users: 'Users management & authorization',
        forwarders: 'Logs forwarding & resource monitoring',
        apiChannels: 'API channels management',
        parser: 'Log Parser Constructor'
      },
      chart: {
        type: 'Chart Type',
        pie: 'Pie Chart',
        line: 'Line Chart',
        bar: 'Bar Chart',
        error: 'Please try again later',
        errorNoData: 'No Data Available Or Invalid Type'
      },
      search: {
        result: 'Total {total} events from {start} to {end}',
        errorNoCharts: 'The output cannot be visualized. Please use aggregations.',
        errorNoEvents: 'There are no events within the period. Please change your query or choose another time range.'
      },
      report: {
        daily: 'Daily Report',
        weekly: 'Weekly Report',
        monthly: 'Monthly Report'
      },
      users: {
        errorPasswordNotMatch: 'The confirm password does not match the new password'
      }
    },
    vi: {
      markedIncidents: 'Đánh dấu sự cố',
      dontMarkedIncidents: 'Không đánh dấu sự cố',
      actionsList: 'Danh Sách Hàng Động',
      hide: 'Ẩn',
      assign_placeholder: 'Chọn tài khoản để giao',
      forward_placeholder: 'Chọn tài khoản để chuyển tiếp',
      closed_message: 'đã đóng ticket vào lúc',
      pending_message: 'đang xử lý',
      replyTo: 'Phản hồi lại cho',
      replied: 'đã phản hồi',
      at: 'lúc',
      add_note_massage: 'Thêm ghi chú',
      created_ticket_massage: 'đã tạo ticket cho người phụ trách là',
      assigned_to: 'đã giao cho',
      download_message: 'Bấm để tải file đính kèm',
      Unknow: 'Ai đó',
      you: 'Bạn',
      reopen: 'Mở Lại',
      forward: 'Chuyển tiếp',
      addNote: 'Thêm ghi chú',
      reply: 'Phản hồi',
      critical: 'Khẩn Cấp',
      assignedTo: 'Giao Việc Cho',
      total: 'Tổng Số',
      previous: 'Quay Lại',
      next: 'Tiếp Theo',
      incidentsDetail: 'Chi tiết Ứng Phó Sự Cố',
      changeStatus: 'Đổi Trạng Thái',
      infomation: 'Thông Tin',
      high: 'Cao',
      medium: 'Trung Bình',
      low: 'Thấp',
      close: 'Đóng',
      toDo: 'Thực hiện',
      maintainer: 'Người Phụ Trách',
      created: 'Đã Tạo',
      eventsViewer: 'Thông tin sự kiện',
      createdAt: 'Tạo Lúc',
      alertToMaintainer: 'Gửi Cảnh Báo Sau Khi Tạo',
      dontAlertToMaintainer: 'Không Gửi Cảnh Báo Sau Khi Tạo',
      remind: 'Nhắc nhở',
      dontRemind: 'Không Nhắc Nhở',
      deadline: 'Thời hạn',
      remindBeforeDeadline: 'Nhắc nhở trước thời hạn',
      events: 'Sự Kiện',
      incidentsHistogram: 'Lịch sử xử lý sự cố',
      unassigned: 'Chưa Giao',
      overdue: 'Quá Hạn',
      pending: 'Đang Chờ Xử lý',
      closed: 'Đã Đóng',
      priority: 'Ưu Tiên',
      falsePositive: 'Cảnh Báo Nhầm',
      inProcess: 'Đang Xử Lý',
      solved: 'Đã Xử Lý',
      add: 'Thêm',
      save: 'Lưu',
      customize: 'Tùy Chỉnh',
      cancel: 'Hủy',
      edit: 'Sửa',
      remove: 'Xóa',
      delete: 'Xóa',
      random: 'Ngẫu Nhiên',
      copy: 'Sao Chép',
      query: 'Truy Vấn',
      schedule: 'Đặt Lịch',
      refresh: 'Làm Mới',
      derived: 'Định Nghĩa',

      tickets: 'Vé',
      name: 'Tên',
      title: 'Tiêu Đề',
      description: 'Mô Tả',
      details: 'Chi Tiết',
      enabled: 'Đã Bật',
      disabled: 'Đã Tắt',
      status: 'Trạng Thái',
      creator: 'Người Tạo',
      lastAlert: 'Cảnh Báo Cuối',
      operator: 'Toán Tử',
      value: 'Giá Trị',
      key: 'Khóa',
      settings: 'Cài Đặt',
      actions: 'Tác Vụ',
      loading: 'Đang Tải...',
      ticketUpdateNotiSuccess: 'Vé đã được làm mới',
      ticketUpdateNoti: 'Vé đã được cập nhật. Nhấn vào biểu tượng để làm mới',
      editWarning: 'Bạn đang sửa một mục khác. Bạn có chắc muốn bỏ qua mục này',
      rus: 'Xác nhận thực hiện?',
      email: 'Email',
      protocol: 'Giao thức',
      port: 'Cổng',
      accessIPs: 'IP Nguồn',
      types: 'Các Kiểu',
      type: 'Kiểu',
      index: 'Chỉ Mục',
      sourceType: 'Kiểu Nguồn',
      token: 'Token',
      delimiter: 'Phân Cách Sự Kiện',
      field_delimiter: 'Phân Cách Trường',
      comment: 'Bình Luận',
      time: 'Thời Gian',
      fields: 'Các Trường',
      field: 'Trường',
      format: 'Định Dạng',
      regexp: 'RegExp',
      autoDetect: 'Tự Động Phát Hiện',
      autoFill: 'Tự Động Điền',
      maxSize: 'Kích Cỡ Tối Đa',
      unit: 'Đơn Vị',
      modules: 'Mô-đun',
      activeModules: 'Mô-đun Hiện Tại',
      lastEvent: 'Sự Kiện Cuối',
      streams: 'Các Nguồn',
      paths: 'Đường Dẫn',
      excludes: 'Ngoại Trừ',
      intervals: 'Khoảng Thời Gian',
      scopes: 'Quyền',
      password: 'Mật Khẩu',
      oldPassword: 'Mật Khẩu Cũ',
      confirmPassword: 'Nhắc Lại Mật Khẩu',
      lastReport: 'Báo Cáo Cuối',
      repeat: 'Lặp Lại',
      recipients: 'Người Nhận',
      dayOfTheWeek: 'Ngày Trong Tuần',
      dayOfTheMonth: 'Ngày Trong Tháng',
      selectDefault: 'Chọn dưới đây',
      headersAndPayload: 'Headers & Payload',
      method: 'Method',
      url: 'URL',
      apiChannels: 'Kênh API',
      param: 'Tên trường',
      default: 'Mặc định',
      logType: 'Kiểu cấu hình',
      editLog: 'Sửa Dữ liệu nhập',
      log: 'Dữ liệu',

      newPlaybook: 'Thêm Kịch Bản Mới',
      newDashboard: 'Thêm Trang',
      newIncident: 'Thêm Ứng Phó Sự Cố',
      newWidget: 'Thêm Thành Phần',
      newAlert: 'Thêm Cảnh Báo',
      newAction: 'Thêm Hành Động',
      newTicket: 'Thêm Vé Mới',
      newSourceType: 'Thêm Kiểu Nguồn',
      newDataInput: 'Thêm Nguồn Dữ Liệu',
      newIndex: 'Thêm Chỉ Mục',
      newForwarder: 'Thêm Chuyển Tiếp',
      newReport: 'Thêm Báo Cáo',
      newUser: 'Thêm Người Dùng',
      changePassword: 'Đổi Mật Khẩu',
      playbook: 'Kịch bản',
      slaTitle: 'Thời Gian SLA Trung Bình',
      resTime: 'Thời Gian Phản Hồi Trung Bình',

      errorNoWidgets: 'Trang này đang trống',
      errorWidgetsCustomizing: 'Vui lòng lưu hoặc hủy tác vụ chỉnh sửa hiện tại',

      newAPIChannel: 'Thêm Kênh API',

      pageOf: 'Trang {0}/{1}',

      route: {
        dashboards: 'Tổng Quan',
        search: 'Tìm Kiếm',
        alerts: 'Cảnh Báo',
        reports: 'Báo Cáo',
        incidents: 'Ứng phó sự cố',
        tickets: 'Vé',
        actions: 'Hành động',
        playBooks: 'Kịch bản',
        indexes: 'Chỉ Mục',
        sourceTypes: 'Kiểu Nguồn',
        dataInputs: 'Nguồn Dữ Liệu',
        Settings: 'Cài Đặt',
        users: 'Người Dùng',
        forwarders: 'Chuyển Tiếp',
        apiChannels: 'Kênh API',
        parser: 'Cấu Hình Dữ Liệu'
      },
      subtitle: {
        search: 'Truy vấn và Trực quan hóa sự kiện',
        alerts: 'Giám sát dựa trên các số liệu',
        incidents: 'Quản lý ứng phó sự cố',
        tickets: 'Quản lý vé',
        actions: 'Quản lý hành động xử lý sự cố',
        playBooks: 'Quản lý kịch bản ứng phó sự cố',
        indexes: 'Quản lý các chỉ mục',
        reports: 'Báo cáo tổng quan về tình hình an ninh của hệ thống',
        sourceTypes: 'Định nghĩa các kiểu nguồn dữ liệu',
        dataInputs: 'Quản lý các nguồn dữ liệu',
        users: 'Quản lý người dùng và phân quyền',
        forwarders: 'Chuyển tiếp bản ghi và theo dõi tài nguyên',
        apiChannels: 'Quản lý các kênh API',
        parser: 'Tạo các cấu hình Dữ Liệu'
      },
      chart: {
        type: 'Kiểu Biểu Đồ',
        pie: 'Biểu Đồ Tròn',
        line: 'Biểu Đồ Đường',
        bar: 'Biều Đồ Cột',
        error: 'Vui lòng thử lại sau',
        errorNoData: 'Không có dữ liệu hoặc loại biểu đồ không hợp lệ'
      },
      search: {
        result: 'Tất cả {total} sự kiện từ {start} đến {end}',
        errorNoCharts: 'Kết quả không thể trực quán hóa. Vui lòng sử dụng các lệnh gộp cụ thể hơn.',
        errorNoEvents: 'Không có sự kiện nào trong khoảng thời gian này. Vui lòng thay đổi truy vấn hoặc chọn một khoảng thời gian khác.'
      },
      report: {
        daily: 'Báo Cáo Hàng Ngày',
        weekly: 'Báo Cáo Hàng Tuần',
        monthly: 'Báo Cáo Hàng Tháng'
      },
      users: {
        errorPasswordNotMatch: 'Mật khẩu nhắc lại không trùng khớp với mật khẩu mới'
      },
      teible: {
        showing: 'Hiển thị',
        total: 'trên tổng số {count} bản ghi',
        last: 'bản ghi cuối cùng',
        empty: 'Không có dữ liệu',
        filter: 'Lọc các bản ghi'
      }
    }
  }
}
